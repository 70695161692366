import request from '@/utils/request'


// 查询秒杀时间轴列表
export function listSeckillTime(query) {
  return request({
    url: '/seckill/seckill/time/list',
    method: 'get',
    params: query
  })
}

// 查询秒杀时间轴分页
export function pageSeckillTime(query) {
  return request({
    url: '/seckill/seckill/time/page',
    method: 'get',
    params: query
  })
}

// 查询秒杀时间轴详细
export function getSeckillTime(data) {
  return request({
    url: '/seckill/seckill/time/detail',
    method: 'get',
    params: data
  })
}

// 新增秒杀时间轴
export function addSeckillTime(data) {
  return request({
    url: '/seckill/seckill/time/add',
    method: 'post',
    data: data
  })
}

// 修改秒杀时间轴
export function updateSeckillTime(data) {
  return request({
    url: '/seckill/seckill/time/edit',
    method: 'post',
    data: data
  })
}

// 删除秒杀时间轴
export function delSeckillTime(data) {
  return request({
    url: '/seckill/seckill/time/delete',
    method: 'post',
    data: data
  })
}
