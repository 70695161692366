import request from '@/utils/request'


// 查询秒杀库存列表
export function listSeckill(query) {
  return request({
    url: '/zb/seckill/list',
    method: 'get',
    params: query
  })
}

// 查询秒杀库存分页
export function pageSeckill(query) {
  return request({
    url: '/zb/seckill/page',
    method: 'get',
    params: query
  })
}

// 查询秒杀库存详细
export function getSeckill(data) {
  return request({
    url: '/zb/seckill/detail',
    method: 'get',
    params: data
  })
}

// 新增秒杀库存
export function addSeckill(data) {
  return request({
    url: '/zb/seckill/add',
    method: 'post',
    data: data
  })
}

// 修改秒杀库存
export function updateSeckill(data) {
  return request({
    url: '/zb/seckill/edit',
    method: 'post',
    data: data
  })
}

// 删除秒杀库存
export function delSeckill(data) {
  return request({
    url: '/zb/seckill/delete',
    method: 'post',
    data: data
  })
}

export function updateSeckillaudit(data) {
  return request({
    url: '/zb/seckill/audit',
    method: 'post',
    data: data
  })
}
